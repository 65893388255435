<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-toolbar-title>Registration</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="6">
                <v-toolbar dark color="panel1">
                    <v-toolbar-title>New Registration</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-3">
                <v-container class="mt-3">
                    <v-form ref="mainForm">
                        <v-text-field 
                            label="Login ID" 
                            outlined 
                            v-model="form.customer_login_id" 
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field 
                            label="Password" 
                            outlined 
                            v-model="form.password" 
                            type="password"
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field 
                            label="PIN (6-digit)" 
                            outlined 
                            v-model="form.pin" 
                            type="password"
                            maxlength=6
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field 
                            label="Full Name" 
                            outlined 
                            v-model="form.full_name" 
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field 
                            label="Mobile Phone No." 
                            outlined 
                            v-model="form.cell_phone" 
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field 
                            label="Email Address" 
                            outlined 
                            v-model="form.email" 
                        ></v-text-field>
                        <v-menu v-model="dpicker.menu" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="form.registration_date"
                                    label="Registration Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.registration_date" @change="dpicker.menu = false"></v-date-picker>
                        </v-menu>

                        <v-checkbox
                            v-model="form.skip_activation"
                            label="Skip Activation"
                            value=1
                        ></v-checkbox>
                    </v-form>
                </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
            </v-card-actions>

            </v-card>
            </v-col>

            <v-col cols="12" md="6">
                    <v-toolbar dark color="panel2">
                        <v-toolbar-title>Registration Log</v-toolbar-title>
                    </v-toolbar>
                    <v-card class="mt-3">
                    <v-container class="mt-3">
                        <v-list>
                            <template v-for="(log,index) in logs">
                            <v-list-item four-line :key="'key-' + index">
                                <v-list-item-content>
                                    <v-list-item-title>{{log.created_date}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                    {{log.login_id}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{log.full_name}} > {{log.cell_phone}} > {{log.email}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                    {{log.mbr_status}} > {{log.verification_code}} > {{log.verification_status}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider  :key="'keyd-' + index"></v-divider>
                                </template>
                                
                        </v-list>
                    </v-container>
                </v-card>


            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApCustomerService from "@/services/ApCustomerService"
import moment from 'moment'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Customer',disabled: false,href:'',},
              {text:'Registration',disabled: false,href:'#',},
              ],
            search_word:"",
            form:{
                customer_login_id:"",
                password:"",
                pin:"",
                full_name:"",
                login_type:"",
                cell_phone:"",
                email:"",
                skip_activation: "1",
                registration_date: "",
            },
            custData:[],
            logo:'',
            custExist:false,
            dpicker:{menu1: false,menu2: false,},
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length == 6 || 'Need 6 digit',
                digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
              },
            logs:[],


    }
  },

    async created(){
        this.form.registration_date = moment(String(new Date())).format('YYYY-MM-DD');
        //this.getParameter();
    },
    computed:{

    },

  
    methods:{

        dlgClose(){
            this.visible = false;        
        },
        async save(){
            if(this.$refs.mainForm.validate()) {
                try{
                    ApCustomerService.insert_customer(this.form).then((res) => {
                        console.log(res.data.data);
                        this.logs.unshift(res.data.data);
                        this.$refs.dlgMsg.showDlgBox(true,'success','Customer Registration','Success.');
                        //this.resetForm();
                    }).catch((e)=>{
                        console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Customer Registration',e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    console.log(e);
                    //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
                }
            }

        },
        resetForm(){
            this.form.customer_login_id = ""
            this.form.password = ""
            this.form.pin = ""
            this.form.full_name = ""
            this.form.cell_phone = ""
            this.form.email = ""
            this.form.skip_activation = ""                

        },


  }
}
</script>